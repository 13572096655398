<template>
    <v-container fluid>
        <v-form>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'product'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <ValidationProvider ref="photo" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <make-photo v-model="photo"/>
                                    <div v-if="!valid" class="mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('photo_not_added') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="9">
                                        <ValidationProvider ref="product" rules="required|min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="product" type="text" :error="!valid"
                                                          :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-shopping"
                                                          :label="$t('product_name')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="3">
                                        <ValidationProvider ref="price" name="price"
                                                            rules="required|min_value:1|max_value:999999"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="price" type="text" :error="!valid"
                                                          :error-messages="errors" :disabled="loading"
                                                          prepend-icon="mdi-sack"
                                                          :label="$t('price')"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="shop" name="shop" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="shop" :items="itemShop"
                                                      :error-messages="errors" :error="!valid"
                                                      :disabled="loadingShop" item-text="name"
                                                      item-value="id" return-object
                                                      prepend-icon="mdi-home-flood" :label="$t('shop')"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="category_product" name="category_product" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="category_product" :items="itemCategoryProduct" :error="!valid"
                                                      :error-messages="errors" :disabled="loadingCategoryProduct" item-text="name"
                                                      item-value="id" return-object
                                                      prepend-icon="mdi-folder-outline" :label="$t('category_product')"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" class="pt-2 mb-0 pb-0">
                                <ValidationProvider ref="short_description" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="short_description"
                                                :error-messages="errors" :disabled="loading"
                                                :label="$t('short_description')"
                                                rows="10" row-height="15" auto-grow outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" class="pt-0">
                                <v-subheader class="pt-0 mb-2 font-weight-medium">
                                    {{ $t('product_description') }}
                                </v-subheader>
                                <ValidationProvider ref="description"
                                                    rules="required|min:3|max:65535"
                                                    v-slot="{ errors, valid }">
                                    <tinymce-editor id="description" v-model="description"
                                                    :init="tinymceInit"></tinymce-editor>
                                    <div v-show="!valid" class="pt-0 mt-2" style="color: red">
                                        {{ errors[0] ? errors[0] : $t('description_is_not_filled_out') }}
                                    </div>
                                </ValidationProvider>
                            </v-col>


                            <v-col class="pt-0" cols="12">
                                <v-expansion-panels :disabled="loading">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="primary white--text">
                                            {{ $t('photo') }}
                                            <template v-slot:actions>
                                                <v-icon color="white">$expand</v-icon>
                                            </template>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <media-gallery v-model="images"/>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="is_adult" :disabled="loading"
                                          :label="$t('is_adult')"
                                          color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="is_recipe" :disabled="loading"
                                          :label="$t('is_recipe')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="deleted ? $t('deleted') : $t('delete')"
                                          @change="deleted ? active = false : active"
                                          color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('published')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-8">
                        <v-progress-linear v-model="progress" :active="loading"
                                           class="mx-2"></v-progress-linear>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveProduct()" :disabled="invalid || loading"
                               :loading="loading" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import {mapGetters} from "vuex"
import MediaGallery from '../components/MediaGallery.vue'
import TinymceEditor from '@tinymce/tinymce-vue'
import MakePhoto from "../components/MakePhoto";

export default {
    name: 'ProductForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakePhoto,
        MediaGallery,
        TinymceEditor
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            loadingCategoryProduct: false,
            loadingCompanies: false,
            loadingShop: false,
            tab: 0,
            language: null,
            errors: {},
            id: null,
            photo: null,
            description: null,
            short_description: null,
            category_shop_id: null,
            price: 0,

            category_product: null,
            itemCategoryProduct: [],
            shop: null,
            itemShop: [],

            is_adult: false,
            is_recipe: false,


            active: false,
            deleted: false,
            product: null,
            images: [],
            exist_translations: {},
            all_translations: true,
            passwordHidden: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        get_short_description() {
            return this.short_description ? this.short_description : (this.description ? this.description.stripTags().substr(0, 255) : null)
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        }
    },

    async mounted() {
        this.language = this.languages[this.tab]
        this.loading = true
        await this.getShops()
        await this.checkCreate()


    },
    watch: {
        shop() {
            if (this.shop) {
                this.getCategoriesProduct()
            }
        },
    },
    methods: {
        async checkCreate() {
            if (this.$route.name === "product.create") {
                this.heading = this.$t('product_creation')
                this.loading = false
            } else {
                this.heading = this.$t('product_editing')
                if (this.$route.params.id) {
                    await this.getProduct()

                }
            }
        },
        async setLanguage(val) {
            this.language = this.languages[val]
            await this.getShops()
            await this.checkCreate()
        },

        async getShops() {
            this.loadingShop = true;
            let params = {}
            params.filter = "search";
            params.is_merchant = 1;
            await this.$http
                .get(`admin/car_energy/`, {params: params})
                .then(res => {
                    this.itemShop = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_shops'));
                    this.itemShop = []

                })
                .finally(end => {
                    this.loadingShop = false
                });
        },
        async getCategoriesProduct() {
            this.loadingCategoryProduct = true;
            let params = {}
              if (this.shop) {
                if (this.shop.category_shop_id) {
                    params.category_shop = this.shop.category_shop_id
                } else {
                    params.category_shop = this.category_shop_id
                }
            }

            await this.$http
                .get(`admin/category/`, {params: params})
                .then(res => {
                    //console.log(res.body.data[0].id);
                    this.itemCategoryProduct = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_categories'));
                    //console.log(err)
                    this.itemCategoryProduct = []
                })
                .finally(end => {
                    this.loadingCategoryProduct = false
                });
        },
        async getProduct() {
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/product/${this.$route.params.id}`, {params: params})
                .then(async (res) => {
                    this.product = res.body.data.name
                    this.photo = res.body.data.photo
                    this.images = res.body.data.images
                    this.description = res.body.data.description
                    this.short_description =  res.body.data.short_description
                    this.category_shop_id = res.body.data.category_shop_id
                    this.category_product = res.body.data.category_id
                    this.shop = res.body.data.shop_id
                    this.price = res.body.data.price
                    this.active = res.body.data.active
                    this.is_adult = res.body.data.is_adult
                    this.is_recipe = res.body.data.is_recipe
                    this.deleted = res.body.data.deleted
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations

                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_product'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async saveProduct() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.product) {
                formData.append('product', this.product)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.deleted) {
                formData.append('deleted', 1)
            }
            if (this.description) {
                formData.append('description', this.description)
            }
            if (this.price) {
                formData.append('price', this.price)
            }
            if (this.get_short_description) {
                formData.append('short_description', this.get_short_description)
            }
            if (this.is_adult) {
                formData.append('is_adult', 1)
            }
            if (this.is_recipe) {
                formData.append('is_recipe', 1)
            }
            if (this.category_product) {
                if (this.category_product.id) {
                    formData.append('category_product', this.category_product.id)
                } else {
                    formData.append('category_product', this.category_product)
                }
            }
            if (this.shop) {
                if (this.shop.id) {
                    formData.append('shop', this.shop.id)
                } else {
                    formData.append('shop', this.shop)
                }
            }
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo)
                    var blob = this.dataURL64toBlob(this.photo)
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType)
                    }
                } else {
                    formData.append('photo', this.photo)
                }
            }

            if (this.images && this.images.length > 0) {
                for (let i in this.images) {
                    if (this.images[i].length > 250) {
                        var mimeTypeImages = await this.getMimeType(this.images[i])
                        var blobImages = await this.dataURL64toBlob(this.images[i])
                        if (mimeTypeImages && blobImages) {
                            formData.append(`images[${i}]`, blobImages, mimeTypeImages)
                        }
                    } else {
                        formData.append(`images[${i}]`, this.images[i])
                    }
                }
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/product/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('product_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('product_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/product', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('product_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'product.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'product'
                            })
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('product_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
